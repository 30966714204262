import {onBeforeMount, onBeforeUnmount} from 'vue';

let bodyClassComposition = (desiredClass) => {
    let finalClass = [];
    if (Array.isArray(desiredClass)) {
        finalClass = desiredClass;
    } else {
        finalClass = desiredClass.split(' ');
    }
    return {
        onBeforeMount : onBeforeMount(() => {
            if (utilities.isSSR()) {
                return;
            }
            finalClass.forEach(className => {
                document.body.classList.add(className);
            });
        }),
        onBeforeUnmount : onBeforeUnmount(() => {
            if (utilities.isSSR()) {
                return;
            }
    
            finalClass.forEach(className => {
                document.body.classList.remove(className);
            });
        })
    }
}

export default bodyClassComposition;
